<template>
  <div class="pageBox">
    <el-card shadow="never">
      <div class="opaBox">
        <el-button type="primary" @click="addBtn"><el-icon><Plus /></el-icon>&nbsp;新增</el-button>
      </div>
      <el-table :data="tableData" border style="width: 100%" row-key="id" v-loading="loading">
        <el-table-column prop="title" label="名称" width="200" />
        <el-table-column prop="id" label="ID" width="150" />
        <el-table-column prop="order" label="排序" sortable width="150" />
        <el-table-column prop="uri" label="路径" />
        <el-table-column label="图标">
          <template #default="scope">
            <div class="iconBox" v-if="scope.row.icon">
              <el-image :src="scope.row.icon" class="icon"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="180">
          <template #default="scope">
            <el-switch
              v-model="scope.row.is_enabled"
              :active-text="scope.row.is_enabled ? '启用' : ''"
              @change="changeType(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200" align="center">
          <template #default="scope">
            <el-button type="primary" @click="editBtn(scope.row)">编辑</el-button>
            <el-button type="danger" @click="delBtn(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog v-model="addDialog" :title="item.id ? '编辑菜单' : '添加菜单'" width="500px">
      <el-form :model="item" label-position="right" label-width="100px">
        <el-form-item label="上级菜单">
          <el-select v-model="treeDataValue.id" placeholder="请选择" clearable>
            <el-option :value="treeDataValue.id" :label="treeDataValue.title" style="height: auto;">
              <el-tree
                :data="tableData"
                ref="tree"
                node-key="id"
                :highlight-current="true"
                :props="defaultProps"
                @node-click="handleNodeClick" />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称">
          <el-input v-model="item.title" style="width: 200px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-upload
            class="avatar-uploader"
            action="/api/upload/img"
            :on-error="handleUploadError"
            :headers="headers"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
            :on-success="handleSuccess"
            :file-list="iconImage"
            :show-file-list="true"
            list-type="picture-card"
            :limmit="1"
            accept=".png,.jpg,.gif">
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input v-model="item.uri" style="width: 200px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="item.order" style="width: 200px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch v-model="item.is_enabled" :active-text="item.is_enabled ? '启用' : ''" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="submitBtn">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 预览图片 -->
    <el-dialog v-model="picVisible" center width="800px">
      <img :src="dialogImageUrl" style="max-width: 700px; margin: 0 auto;display: block;" />
    </el-dialog>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post } from '@/api/request'
import menu from './menu'
import { Plus } from '@element-plus/icons'

export default {
  components: {
    Plus
  },
  setup() {
    const data = reactive({
      tableData: [],
      loading: false,
      item: {},
      addDialog: false,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Shop-Id': localStorage.getItem('shopId') || ''
      },
      dialogImageUrl: '',
      picVisible: false,
      iconImage: []
    })

    function getMenuList() {
      data.loading = true
      get('/api/menu').then((res) => {
        data.tableData = res.data
        data.loading = false
      }).catch(() => {
        data.loading = false
      })
    }

    const { menuData, handleNodeClick } = menu()

    function addBtn() {
      menuData.treeDataValue = {
        id: '',
        title: ''
      }
      data.item = {
        is_enabled: true,
        icon: ''
      }
      data.iconImage = []
      data.addDialog = true
    }

    function editBtn(item) {
      data.item = {
        ...item
      }
      data.iconImage = item.icon ? [{url: item.icon}] : []
      menuData.treeDataValue = item.parent || {}
      data.addDialog = true
    }

    async function submitBtn() {
      data.item.parent_id = menuData.treeDataValue.id || 0
      let res = ''
      if (data.item.id) {
        res = await post(`/api/menu/${data.item.id}`, data.item, 'put')
      } else {
        res = await post('/api/menu', data.item)
      }
      if (res.code === 0) {
        ElMessage({ type: 'success', message: data.item.id ? '编辑成功' : '添加成功' })
        data.addDialog = false
        getMenuList()
      }
    }

    async function changeType(item) {
      data.item = { ...item }
      await post(`/api/menu/${data.item.id}`, data.item, 'put').then(() => {
        ElMessage({ type: 'success', message: '编辑成功' })
        getMenuList()
      })
    }

    function delBtn(id) {
      ElMessageBox.confirm('确定删除该菜单吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        post(`/api/menu/${id}`, {}, 'delete').then(() => {
          ElMessage({ type: 'success', message: '删除成功' })
          getMenuList()
        })
      })
    }
    function close() {
      data.addDialog = false
    }

    function handleUploadError(err) {
      if(JSON.parse(err.message) && JSON.parse(err.message).message) {
        ElMessage({ type: 'error', message: JSON.parse(err.message).message })
      }
    }

    function handleRemove(res, ress) {
      data.item.icon = ''
      data.iconImage = []
    }

    function handlePreview(File) {
      data.dialogImageUrl = File.url
      data.picVisible = true
    }

    function handleSuccess(res) {
      if (res.code === 0) {
        data.iconImage = [{url: res.data.link}]
        data.item.icon = res.data.link
      }
    }

    onMounted(() => {
      getMenuList()
    })

    return {
      ...toRefs(data),
      addBtn,
      submitBtn,
      close,
      editBtn,
      delBtn,
      changeType,
      ...toRefs(menuData),
      handleNodeClick,
      handleUploadError,
      handleSuccess,
      handlePreview,
      handleRemove
    }
  }
}
</script>

<style lang="scss" scoped>
  .opaBox{
    margin-bottom: 15px;
  }
  .iconBox{
    background-color: rgba(0,0,0,0.5);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    .icon{
      width: 24px;
      height: 24px;
      margin: 8px;
    }
  }
</style>

<style scoped>
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    background-color: rgba(0,0,0,0.5);
  }
</style>
