import {reactive} from "vue";

export default function menu() {
    const menuData = reactive({
        treeData: '',
        treeDataValue: {},
        defaultProps: {
            parent: 'parent_id', // 父级唯一标识
            value: 'id', // 唯一标识
            label: 'title', // 标签显示
            children: 'children' // 子级
        },
    })
    // 选择
    function handleNodeClick (data){
        menuData.treeData = data.id
        menuData.treeDataValue = data
        console.log(data)
    }

    return {
        menuData,
        handleNodeClick
    }
}